import React from "react";
// Customizable Area Start
import { 
  Box, Typography, Table, 
  TableBody, TableCell, TableContainer,
  TableHead, TableRow, Paper,
  Card, CardContent, 
  styled, CardHeader, IconButton, Collapse
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {arrowImage} from "./assets"
import i18n from "../../../components/src/i18nextConfig/i18n";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { withSearch as withSearchHOC} from '../../../components/src/SearchContext';
import LoaderComponent from "../../../components/src/LoaderContent"

// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderSingleDepartment = () => {
    return (
      <Box>
      <TableContainer component={Paper} sx={{ marginTop: 3, maxHeight: 'calc(80vh - 130px)', overflowY: 'auto',borderRadius:'10px' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow sx={{ height: '46px' }}>
                <CustomTableCell>{i18n.t("Department")}</CustomTableCell>
                <CustomTableCell>{i18n.t("Device")}</CustomTableCell>
                <CustomTableCell>{i18n.t("Device user")}</CustomTableCell>
                <CustomTableCell>{i18n.t("Service")}</CustomTableCell>
                <CustomTableCell>{i18n.t("Article")}</CustomTableCell>
                <CustomTableCell>{i18n.t("Forwarding")}</CustomTableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.singleDepartmentDeviceUser.length > 0 ? (
                this.state.singleDepartmentDeviceUser.map((device: any, index: number) => (
                  <TableRow
                    key={index}
                    style={{cursor:"pointer"}}
                    data-test-id={`device-row-${index}`}
                    onClick={this.handleSingleDevice(device.id__,index+1, device.device)}
                  >
                    <CustomTableCellDark style={{ fontFamily: 'Inter, sans-serif' }}>{device.department}</CustomTableCellDark>
                    <CustomTableCellDark style={{ fontFamily: 'Inter, sans-serif' }}>{device.device}</CustomTableCellDark>
                    <CustomTableCellDark style={{ fontFamily: 'Inter, sans-serif' }}>{device.device_user}</CustomTableCellDark>
                    <CustomTableCellDark style={{ fontFamily: 'Inter, sans-serif' }}>{device.service}</CustomTableCellDark>
                    <CustomTableCellDark style={{ fontFamily: 'Inter, sans-serif' }}>{device.article}</CustomTableCellDark>
                    <CustomTableCellDark style={{ fontFamily: 'Inter, sans-serif' }}>{device.forwarding}</CustomTableCellDark>
                    <TableCell align="right">
                      <Typography variant="h6" component="span" sx={{ cursor: 'pointer', color: "#00496B" }}>
                        <KeyboardArrowRightIcon />
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align="center">{this.state.loadingSingleDepartment && <LoaderComponent/>}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };
  
  renderDepartmentDetails = () => {
    return (
      <TableContainer component={Paper} sx={{ marginTop: 3,borderRadius:'10px'}}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <CustomTableCell style={{ fontFamily: 'Roboto' }}>{i18n.t("Department Name")}</CustomTableCell>
              <TableCell align="right" />
            </TableRow>
          </TableHead>
        </Table>
        <Box sx={{ maxHeight: "calc(80vh - 120px)", overflowY: "auto" }}>
          <Table>
            <TableBody>
              {this.state.dashboardDepartments.length > 0 ? (
                this.state.dashboardDepartments.map((department: any, index: any) => (
                  <TableRow
                  style={{cursor:"pointer"}}
                    key={index}
                    data-test-id={`department-row`}
                    onClick={() => this.handleDepartmentClick(department.department)}
                  >
                    <CustomTableCellDark>{department.department}</CustomTableCellDark>
                    <TableCell align="right" sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography
                        variant="h6"
                        component="span"
                        sx={{ cursor: 'pointer', color: "#00496B" }}
                      >
                        <KeyboardArrowRightIcon/>
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={2} align="center">{this.state.loadingDepartment && <LoaderComponent/>}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </TableContainer>
    );
  };

  handleExpandClick = (section: string,forwarding :string) => () => {
    const userPermission = ['europac',"securitas fe","securitas de"]
    if(section === "expandedDevice") {
      this.setState({ expandedDevice: !this.state.expandedDevice });
    }
    else if(section === "expandedARC" && userPermission.includes(forwarding.toLowerCase()) ){
      this.setState({ expandedARC: !this.state.expandedARC });
    }
    else if(section === "expandedNotification"){
      this.setState({ expandedNotification: !this.state.expandedNotification});
    }
  }
  
  renderAlaramNotification = (notification : any,device : any,connection : any,failureNotification : any) => {
    return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow sx={{backgroundColor: "#F5F5F4"}}>
            <CustomTableDeviceCellleftalignnotification >{i18n.t("Alarm notification mail")}</CustomTableDeviceCellleftalignnotification>
            <CustomHeaderTableCell>{i18n.t("Mail")}</CustomHeaderTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {notification.map((contact: any, index: any) => (
            <TableRow key={index}>
              <CustomTableDeviceCellValue>{contact.name}</CustomTableDeviceCellValue>
              <CustomTableDeviceCellValue>{contact.email}</CustomTableDeviceCellValue>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Table>
        <TableHead>
          <TableRow sx={{backgroundColor: "#F5F5F4",position: "relative", top:"5px"}}>
            <CustomTableDeviceCellleftalignnotification >{i18n.t("Day's no connection")}</CustomTableDeviceCellleftalignnotification>
            <CustomHeaderTableCell >{device.number_of_days_no_connection}</CustomHeaderTableCell>
          </TableRow>
        </TableHead>
      </Table>
      <Table sx={{marginTop: "10px"}}>
        <TableHead>
          <TableRow sx={{backgroundColor: "#F5F5F4"}}>
            <CustomTableDeviceCellleftalignnotification >{i18n.t("No connection mail")}</CustomTableDeviceCellleftalignnotification>
            <CustomHeaderTableCell>{i18n.t("Mail")}</CustomHeaderTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {connection.map((contact: any, index: any) => (
            <TableRow key={index}>
              <CustomTableDeviceCellValue>{contact.name}</CustomTableDeviceCellValue>
              <CustomTableDeviceCellValue>{contact.email}</CustomTableDeviceCellValue>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Table >
        <TableHead>
          <TableRow sx={{backgroundColor: "#F5F5F4"}} >
            <CustomHeaderTableCell >{i18n.t("Failure notification mail")} <br/>{i18n.t("or sms")}</CustomHeaderTableCell>
            <CustomHeaderTableCell>{i18n.t("Mail")}</CustomHeaderTableCell>
            <CustomHeaderTableCell>{i18n.t("Phone Number")}</CustomHeaderTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {failureNotification.map((contact: any, index: any) => (
            <TableRow key={index}>
              <CustomTableDeviceCellValue>{contact.name}</CustomTableDeviceCellValue>
              <CustomTableDeviceCellValue>{contact.email}</CustomTableDeviceCellValue>
              <CustomTableDeviceCellValue>{contact.phone_number}</CustomTableDeviceCellValue>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
  renderEmergencyContacts = (contacts: any) => (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <CustomHeaderTableCell >{i18n.t("Warning Contact")}</CustomHeaderTableCell>
            <CustomHeaderTableCell>{i18n.t("Phone Number 1")}</CustomHeaderTableCell>
            <CustomHeaderTableCell>{i18n.t("Phone Number 2")}</CustomHeaderTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {contacts.map((contact: any, index: any) => (
            <TableRow key={index}>
              <CustomTableDeviceCellValue>{contact.name}</CustomTableDeviceCellValue>
              <CustomTableDeviceCellValue>{contact.tell}</CustomTableDeviceCellValue>
              <CustomTableDeviceCellValue>{contact.tell2}</CustomTableDeviceCellValue>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
  
  renderUserDetails = () => {
    const { expandedDevice, expandedARC,SingleDeviceDataList,expandedNotification } = this.state;
    const device = Array.isArray(SingleDeviceDataList) && SingleDeviceDataList[0];
    return (
      <Box>{
      device &&(
        <Box>
      <Card sx={{ maxWidth: '100vw',margin: '20px auto'}}>
        <CustomCardHeader
          title={i18n.t("Device and User")}
          titleTypographyProps={{
            style: {
              fontFamily: "Roboto",
              fontWeight: "700",
              fontSize: "20px",
              lineHeight: "22px",
              textAlign: "center",
            },
          }}
          action={
            <CustomCloseIconButton
              data-test-id="custom-close-icon-button"
              onClick={this.handleExpandClick('expandedDevice',device.forwarding)}
              aria-expanded={expandedDevice}
              aria-label="show more"
            >
              {expandedDevice &&<ExpandMoreIcon /> } 
            </CustomCloseIconButton>
          }
        />
        <ButtonContainer >
          <CustomOpenIconButton
                onClick={this.handleExpandClick('expandedDevice',device.forwarding)}
                aria-expanded={expandedDevice}
                aria-label="show more"
              >
              {!expandedDevice &&<ExpandMoreIcon sx={{textAlign : "end"}}/> } 
            </CustomOpenIconButton>
          </ButtonContainer>
        <Collapse in={expandedDevice} timeout="auto" unmountOnExit>
          <CardContent sx={{ marginLeft: '', marginRight: "" ,borderRadius : "10px"}}>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <CustomTableDeviceCellleftalign >{i18n.t("Device ID")}:</CustomTableDeviceCellleftalign>
                    <CustomTableDeviceCellValue >{device.device_id}</CustomTableDeviceCellValue >
                  </TableRow>
                  <TableRow>
                      <CustomTableDeviceCellleftalign>{i18n.t("User Name")}:</CustomTableDeviceCellleftalign>
                      <CustomTableDeviceCellValue >{device.device_user}</CustomTableDeviceCellValue >
                  </TableRow>
                  <TableRow>
                      <CustomTableDeviceCellleftalign>{i18n.t("Phone Number")}:</CustomTableDeviceCellleftalign>
                      <CustomTableDeviceCellValue >{device.phone_number}</CustomTableDeviceCellValue >
                  </TableRow>
                  {device.second_phone_number && (
                  <TableRow>
                    <CustomTableDeviceCellleftalign>{i18n.t("Phone Number 2")}:</CustomTableDeviceCellleftalign>
                    <CustomTableDeviceCellValue>{device.second_phone_number}</CustomTableDeviceCellValue>
                  </TableRow>
                  )}
                  <TableRow>
                    <CustomTableDeviceCellleftalign >{i18n.t("Forwarding")}:</CustomTableDeviceCellleftalign>
                    <CustomTableDeviceCellValue >{device.forwarding}</CustomTableDeviceCellValue >
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Collapse>
        </Card>
        <Card sx={{ maxWidth: '100vw',margin: '20px auto'}} >
          <CustomCardHeader
            title="ARC"
            titleTypographyProps={{
              style: {
                fontFamily: "Roboto",
                fontWeight: "700",
                fontSize: "20px",
                lineHeight: "22px",
                textAlign: "center",
              },
            }}
            action={
              <CustomCloseIconButton
              data-test-id="custom-close-icon-button"
                onClick={this.handleExpandClick('expandedARC',device.forwarding)}
                aria-expanded={expandedARC}
                aria-label="show more"
              >
                {expandedARC && <ExpandMoreIcon />}
              </CustomCloseIconButton>
            }
          />
          <ButtonContainer >
            <IconButton
                  onClick={this.handleExpandClick('expandedARC',device.forwarding)}
                  aria-expanded={expandedARC}
                  aria-label="show more"
                  sx={{
                                  
                    color: '#053348',
                    textAlign : "end"
                  }}
                >
                {!expandedARC &&<ExpandMoreIcon sx={{textAlign : "end"}}/> } 
              </IconButton>
          </ButtonContainer>
          <Collapse in={expandedARC} timeout="auto" unmountOnExit>
            <CardContent >
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <CustomTableDeviceCellleftalign >{i18n.t("Potential Threat")}:</CustomTableDeviceCellleftalign>
                      <CustomTableDeviceCellValue>{device.potential_threat}</CustomTableDeviceCellValue>
                    </TableRow>
                    <TableRow>
                      <CustomTableDeviceCellleftalign>{i18n.t("Extra Information")}:</CustomTableDeviceCellleftalign>
                      <CustomTableDeviceCellValue>{device.extra_information}</CustomTableDeviceCellValue>
                    </TableRow>
                    <TableRow>
                      <CustomTableDeviceCellleftalign>{i18n.t("Risk Object")}:</CustomTableDeviceCellleftalign>
                      <CustomTableDeviceCellValue>{device.risk_object }</CustomTableDeviceCellValue>
                    </TableRow>
                    <TableRow>
                      <CustomTableDeviceCellleftalign>{i18n.t("Address")}:</CustomTableDeviceCellleftalign>
                      <CustomTableDeviceCellValue>{device.address}</CustomTableDeviceCellValue>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              </CardContent>
              <CardContent> {this.renderEmergencyContacts(device.emergency_contact)}</CardContent>
              <CardContent>
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <CustomTableDeviceCellleftalign>{i18n.t("Password")}:</CustomTableDeviceCellleftalign>
                      <CustomTableDeviceCellValue>{device.password}</CustomTableDeviceCellValue>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              </CardContent>
          </Collapse> 
        </Card>
        <Card>
          <CustomCardHeader
            title={i18n.t("Notifications")}
            titleTypographyProps={{
              style: {
                fontFamily: "Roboto",
                fontWeight: "700",
                fontSize: "20px",
                lineHeight: "22px",
                textAlign: "center",
              },
            }}
            action={
              <CustomCloseIconButton
              data-test-id="custom-close-icon-button"
                onClick={this.handleExpandClick('expandedNotification',device.forwarding)}
                aria-expanded={expandedNotification}
                aria-label="show more"
              >
                {expandedNotification && <ExpandMoreIcon />}
              </CustomCloseIconButton>
            }
          />
          <ButtonContainer >
            <IconButton
                  onClick={this.handleExpandClick('expandedNotification',device.forwarding)}
                  aria-expanded={expandedNotification}
                  aria-label="show more"
                  sx={{
                                  
                    color: '#053348',
                    textAlign : "end"
                  }}
                >
                {!expandedNotification &&<ExpandMoreIcon sx={{textAlign : "end"}}/> } 
              </IconButton>
          </ButtonContainer>
          <Collapse in={expandedNotification} timeout="auto" unmountOnExit>
            <CardContent sx={{padding:"0px",position:"relative" ,top:"-20px"}} > {this.renderAlaramNotification (device.alarm_notification_mail,device,device.no_cannection_mail,device.failure_notification_mail_of_sms)}</CardContent>
          </Collapse>
        </Card>
        {!this.state.loadingSingleDepartment &&
        <Box sx={{ display: "flex", width: "100%", justifyContent: "end", alignItems: "center" }}>
        {this.renderPreviouseButton(this.state.isPreviousButton)}
        {this.renderNextButton(this.state.isNextButton)}
        </Box>}
      </Box>
      )
      }
      {(this.state.loading || !device) && <LoaderComponent/>}
      </Box>
    )
  }
  renderPreviouseButton = (isPreviousButton : boolean) => {
    if (isPreviousButton) {
      return (
        <CustomButton data-testid="previous-button" onClick={this.handlePreviousClick} sx={{ display: "flex", alignItems: "center", cursor:"pointer"}}>
            <KeyboardArrowLeftIcon sx={{ display: "inline", position: "relative", marginRight: "8px" }} />
            {i18n.t("Previous Device")}
          </CustomButton>
      )
    }
  }
  renderNextButton = (isNextButton : boolean) => {
    if (isNextButton) {
      return(
        <CustomButton onClick={this.handleNextClick} sx={{ display: "flex", alignItems: "center", marginLeft: "16px", cursor:"pointer"}}>
        {i18n.t("Next Device")}
        <KeyboardArrowRightIcon sx={{ display: "inline", position: "relative", marginLeft: "8px" }} />
        </CustomButton>
      )
    }
  }
  renderDashBoradComponent = () => {
    let content;
    if (this.state.selectedDepartmentUser && this.state.isSingleDeviceTab) {
        content = this.renderUserDetails();
    }
    else if(this.state.isSearchTab){
      content = this.renderSingleDepartment();
    }
    else if (this.state.selectedDepartment && this.state.isSingleDepartmentTab) {
        content = this.renderSingleDepartment();
    } else if(this.state.singleDepartmentDeviceUser && this.state.isDepartmentTab){
        content = this.renderDepartmentDetails();
    }
    return content;
  }
  isSingleDeviceTab = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const deviceUser = urlParams.get("deviceUser") || "";
    if(!deviceUser){
      return false
    }
    return true;
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <MainContainer >
        <Card variant="outlined" sx = {{height: '64px',marginTop: '16px', borderRadius:'10px'}}>
        <CardContent sx = {{display : "flex",color: "#053348" ,padding:"11px 16px"}}>
            <CustomTypoText variant="h5" sx={{position:"relative",top:"4px"}} onClick = {this.getDashboardDepartmentDataPage}>
              {i18n.t("Overview")}
            </CustomTypoText>
           {this.state.selectedDepartment && 
           <CustomTypoText variant="h5"  onClick = {() => this.handleDepartmentClick(this.state.selectedDepartment)}>
            <ArrowImage src = {arrowImage.default} alt="arrow"/>
              {this.state.selectedDepartment} 
            </CustomTypoText>}
            { this.state.isSearchTab &&
           <CustomTypoText variant="h5" onClick = {this.handleAllSearch}>
            <ArrowImage src = {arrowImage.default} alt="arrow"/>
              {i18n.t("Search Result") } 
            </CustomTypoText>}
           { this.isSingleDeviceTab() &&
           <CustomTypoText variant="h5">
            <ArrowImage src = {arrowImage.default} alt="arrow"/>
              {this.state.selectedDepartmentUser } 
            </CustomTypoText>}
          </CardContent>
        </Card>
        <Box sx={{ marginTop: 2 }} />
        {this.renderDashBoradComponent()}
      </MainContainer>
    );
    // Customizable Area End
  }
}
export default withSearchHOC(Dashboard);
// Customizable Area Start
const CustomTableCell = styled(TableCell)({
  color: '#053348',
  position: 'sticky',
  fontFamily: 'Inter, sans-serif',
  fontSize: '14px',
  fontWeight: '700'
});
const CustomTableCellDark = styled(TableCell)({
  color: '#0F172A',
  height: '46px'
});
const CustomTypoText = styled(Typography)({
  cursor: "pointer",
  borderRadius: "10px",
  fontWeight: "700",
  fontFamily:"Roboto",
  fontSize:"24px"
})
const MainContainer = styled(Box)({
  padding: 3, backgroundColor: '#E9F6FF',
  margin: "10px",
  borderRadius: "10px"
})
const ArrowImage = styled("img")({
  height:"30px",
  width: "30px",
  position: "relative",
  top: "6px",
  display: "inline"
})
const CustomTableDeviceCellleftalign = styled(TableCell)({
  fontWeight: '700', 
  maxWidth:'33.3333%',
  color:"#053348",
  fontSize : "14px",
  fontFamily : "Roboto",
  padding:"16px 16px 16px 5% !important",

})
const CustomTableDeviceCellleftalignnotification = styled(TableCell)({
  fontWeight: '700',
  maxWidth:'16.5%',
  color:"#053348",
  fontSize : "14px",
  fontFamily : "Roboto",
  padding:"16px 16px 16px 5% !important",
})
const CustomTableDeviceCellValue = styled(TableCell)({
  color: "#053348",
  fontWeight: '400',
  fontSize : "14px",
  fontFamily : "Roboto",
  padding:"16px 16px 16px 5% !important",

})
const ButtonContainer = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection:"row-reverse"
})
const CustomCardHeader = styled(CardHeader)({
  backgroundColor: "#053348",
  color: '#fff',
  fontWeight: 'bold',
  borderTopLeftRadius : "10px",
  borderTopRightRadius : "10px"
})
const CustomCloseIconButton = styled(IconButton)({
  transition: 'transform 0.3s ease',
  color: '#fff',
})
const CustomOpenIconButton = styled(IconButton)({
  color: '#053348',
  textAlign : "end"
})
const CustomHeaderTableCell = styled(TableCell)({
  fontWeight: 'bold', 
  maxWidth: '33.3333%',
  color : "#053348",
  padding:"16px 16px 16px 5% !important",

})
const CustomButton = styled('button')({
  background:"none",
  border: "none",
  padding: "10px",
  color : "#00496B",
  fontSize : "16px",
  fontWeight : "700",
  fontFamily : "Roboto"
})
const webStyles = {
  container: {
    display: "grid",
    gridTemplateColumns: "3fr 7fr",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    width: "32rem",
    padding: "0.5rem 1rem",
    minHeight: "12rem",
    gap: "2rem"
  },
  totalCandidates: {
    display: "grid",
    gridTemplateRows: "3fr 7fr",
    alignItems: "start",
    justifyContent: "center",
    justifyItems: "center",
    color: "black",
    margin: "1rem 0rem"
  },
  candidatesFontSize: {
    fontSize: "1rem"
  },
  btn: {
    backgroundColor: "blue",
    color: "white",
    borderRadius: "0",
    marginTop: "0.5rem",
    padding: "0.125rem"
  },
  fontSizeForType: { fontSize: "1 rem" },
  mediumFontSizeForType: { fontSize: "3rem" },
  itemStyle: { margin: "1rem 0rem" },
  type: { fontSize: "0.8rem", textTransform: "capitalize" },
  titletext: {
    color: "grey",
    fontWeight: "lighter",
    fontSize: "3rem"
  },
  typeText: { fontSize: "0.8rem", textTransform: "capitalize" }
};
// Customizable Area End
