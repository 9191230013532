Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.errorTitle = "Error";

exports.apiVerifyOtpContentType = "application/json";
exports.apiVerifyOtpEndPoint =
  "account/accounts/sms_confirmation?pin=";

exports.apiVerifyForgotPasswordOtpEndPoint =
  "forgot_password/otp_confirmation";

exports.apiVerifyOtpMethod = "POST";

exports.errorOtpNotValid = "OTP is not valid.";

exports.btnTxtSubmitOtp = "Submit otp";

exports.placeHolderOtp = "OTP";

exports.labelInfo =
  "We Just sent a 4 digits OTP to phone. Enter your OTP code below.";

exports.submitButtonColor = "#6200EE";

// Customizable Area Start
exports.newOTPTeaxt = "A new OTP has been sent."
exports.OTPWebSubLabel = "Enter the 6-digit code that we sent to the number ending in"
exports.OTPWebLabel = "Enter OTP for Verification"
exports.OTPSentText = "A new OTP has been sent."
exports.apiWebOtpEndPoint = "bx_block_forgot_password/verify_otp_dashboard_user"
exports.webActivateApiEndPoint = "account_block/activate_account_user"
exports.webActivateMethod = "POST"
exports.resendOTPApiEndPoint = "account_block/create_new_password_for_dashboard_user"
// Customizable Area End