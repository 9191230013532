// Customizable Area Start
import React from "react";
import { Box, Typography, styled} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close"
import i18n from "../../../components/src/i18nextConfig/i18n";
import OTPInputAuthController, { 
    Props
} from "./OTPInputAuthController.web";
import LoadingSpinner from "../../../components/src/LoadingSpinner";
import { LoginHelper } from "../../../components/src/LoginHelper";

// Customizable Area End
export default class OTPInputAuth extends OTPInputAuthController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  setLoginHandler = (handler: (token: string) => void) => {
    this.loginHandler = handler;
  };

  // Customizable Area End
  render() {
    // Customizable Area Start
    const {t} =  i18n; 
    const { remainingTime, isRequestEnabled } = this.state;
    return (
      
      <>
      <LoginHelper onLoginHandlerReady={this.setLoginHandler} />
        <Main>
          {this.state.loading && <LoadingSpinner/>}
          <Body>
          <HeaderContainer>
            <Header variant="h4" >
                { t(this.state.OTPWebLabel)}
            </Header>
            <SubHeader variant="h1">
              {t(this.state.OTPWebSubLabel )+ " "+ this.state.mobileNumber }
            </SubHeader>
            { this.state.isSuccessAlert && !this.state.isErrorAlert && <ShowMessageAlert>
              <MessageText>{ t ( this.state.alertMessage)}</MessageText>
              <CloseIcon onClick={this.handleCloseAlert}
              style={{margin:"10px", fontSize:"1rem"}}
      />
            </ShowMessageAlert>}
            { this.state.isErrorAlert && !this.state.isSuccessAlert && <ShowMessageAlert className="error-message">
              <MessageText>{ 
                t(this.state.alertMessage)
              }</MessageText>
              <CloseIcon onClick={this.handleCloseAlert}
              style={{margin:"10px", fontSize:"1rem"}} />
            </ShowMessageAlert>}
          </HeaderContainer>
          <OTPContainer>
              {this.state.otpValues.map((value, index) => (
                <OTPInput
                  key={index}
                  id={`otp-input-${index}`}
                  type="text"
                  value={value}
                  onChange={(e :React.ChangeEvent<HTMLInputElement>) => this.handleOTPChange(index, e)}  // Handle digit input
                  onKeyDown={(e : React.KeyboardEvent<HTMLInputElement>) => this.handleBackspace(index, e)} // Handle backspace key press
                  autoFocus={index === 0 && value === ""}
                  maxLength={1}
                />
              ))}
            </OTPContainer>
            <BottomContainer>
            {!isRequestEnabled && <TimeText>{this.formatTime(remainingTime)}</TimeText>}
              <RequestText
                onClick={isRequestEnabled ? this.handleRequestNewOTP : undefined}
                className={isRequestEnabled ? "enabled" : "disabled"}
              >
                {t ( "Request a new code") }
              </RequestText>
            <VerifyButton
            className={this.state.isButtonEnabled ? "enabled" : "disabled"}
            disabled={!this.state.isButtonEnabled}
            onClick={this.handleOTPsubmit}
            >{t ("Verify")} </VerifyButton>
            </BottomContainer>

          </Body>
        </Main>
      </>
      
    );
    // Customizable Area End
  }

}
// Customizable Area Start
const Main = styled(Box)(({ theme }) => ({
  padding: commonStyles.paddingLarge,
  display: "flex",
  alignItems: "center",
  background: commonStyles.backgroundPrimary,
  justifyContent: "center",
  height: "calc(100vh - 130px)",
  maxWidth: "100%",
  [theme.breakpoints.down("sm")]: {
    padding: commonStyles.paddingMedium,
  },
}));

const Body = styled(Box)(({ theme }) => ({
  background: "#FFFFFF",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "30px 10px",
  borderRadius: "10px",
  width: "100%",
  maxWidth: "477px",
  justifyContent: "space-between",
  height: "65vh",
  [theme.breakpoints.down("sm")]: {
    padding: "20px 5px",
    height: "auto",
    maxWidth: "100%",
  },
}));

const HeaderContainer = styled("div")({
  textAlign: "center",
});

const Header = styled(Typography)(({ theme }) => ({
  fontFamily: commonStyles.fontFamily,
  fontSize: commonStyles.fontSizeLarge,
  marginBottom: "15px",
  color: commonStyles.colorPrimary,
  fontWeight: 800,
  [theme.breakpoints.down("sm")]: {
    fontSize: commonStyles.fontSizeMedium,
  },
}));

const SubHeader = styled(Typography)(({ theme }) => ({
  fontFamily: commonStyles.fontFamily,
  fontSize: commonStyles.fontSizeMedium,
  fontWeight: 500,
  color: commonStyles.colorPrimary,
  padding: "0px 40px",
  [theme.breakpoints.down("sm")]: {
    fontSize: commonStyles.fontSizeSmall,
    padding: "0px 20px",
  },
}));

const OTPContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginTop: "20px",
  marginBottom: "20px",
  [theme.breakpoints.down("sm")]: {
    marginTop: "15px",
    marginBottom: "15px",
  },
}));

const OTPInput = styled("input")(({ theme }) => ({
  width: "40px",
  height: "40px",
  margin: "0 5px",
  textAlign: "center",
  fontSize: "18px",
  color:"#64748B",
  borderRadius: commonStyles.borderRadius,
  border: commonStyles.border,
  outline: "none",
  backgroundColor: "#ffffff",
  [theme.breakpoints.down("sm")]: {
    width: "35px",
    height: "35px",
    fontSize: "16px",
    margin: "0 3px",
  },
}));

const BottomContainer = styled("div")({
  textAlign: "center",
  maxWidth: "100%",
});

const TimeText = styled(Typography)(({ theme }) => ({
  color: "#0F172A",
  fontSize: "16px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
  },
}));

const RequestText = styled(Typography)(({ theme }) => ({
  cursor: "not-allowed",
  margin: "20px 0px",
  fontSize: "14px",
  fontWeight: "700",
  color: "#94A3B8",
  "&.enabled": {
    color: "#00496B",
    cursor: "pointer",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
    margin: "15px 0px",
  },
}));

const VerifyButton = styled("button")(({ theme }) => ({
  background: "#A0AEC0",
  border: "none",
  color: "#ffffff",
  fontWeight: "700",
  width: "100%",
  maxWidth: "391px",
  height: "56px",
  padding: "14px 130px",
  fontSize: "16px",
  borderRadius: commonStyles.borderRadius,
  cursor: "not-allowed",
  opacity: 0.6,
  "&.enabled": {
    background: commonStyles.backgroundDark,
    cursor: "pointer",
    opacity: 1,
  },
  [theme.breakpoints.down("sm")]: {
    maxWidth: "100%",
    height: "50px",
    fontSize: commonStyles.fontSizeSmall,
  },
}));
const ShowMessageAlert = styled(Box)(({ theme }) => ({
  background: "#ECFDF5",
  borderLeft: "4px solid #059669",
  width: "320px",
  maxWidth: "100%",
  height: "48px",
  padding: "12px 10px",
  position: "relative",
  left: "2vw",
  display: "flex",
  alignItems: "center",
  marginTop: "20px",
  justifyContent: "space-between",
  borderRadius: "4px 0px 0px 0px",
  color: "#0F172A",
  opacity: 1,
  [theme.breakpoints.down("sm")]: {
    width: "90%",
    left: "2vw",
    padding: "8px",
    height: "auto",
  },
  "&.error-message": {
    background: "#FEE2E2",
    borderLeft: "4px solid #E4022E",
    color: "#E4022E",
  },
}));

const MessageText = styled(Typography)(({ theme }) => ({
  fontFamily: "Roboto",
  fontSize: "14px",
  fontWeight: 400,
  textAlign: "left",
  padding: "0 10px",
  flex: 1,
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
    padding: "0 8px",
  },
}));
const commonStyles = {
  fontFamily: "Roboto",
  colorPrimary: "#053348",
  backgroundPrimary: "#E9F6FF",
  borderRadius: "5px",
  border: "1px solid #CBD5E1",
  backgroundLight: "#f3f3f3",
  backgroundDark: "#00496B",
  backgroundError: "#FEE2E2",
  backgroundSuccess: "#ECFDF5",
  fontSizeLarge: "28px",
  fontSizeMedium: "18px",
  fontSizeSmall: "16px",
  fontSizeXSmall: "14px",
  paddingLarge: "20px",
  paddingMedium: "10px",
  paddingSmall: "5px",
};


// Customizable Area End
